.caContentWrapper {
    width: 100%;
    padding: 0px 64px;
    margin-top: 18px;
    height: calc(100vh - 120px);
}

.caHeaderWrapper {
    width: calc(100vw - 128px);
    background-color: #FFFFFF;
}

.flexbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.searchBoxWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
}

.search_pill_ca {
    width: 392px;
    margin-left: auto;
}

.search_pill_ca .search__icon {
    display: none;
}

.search_pill_ca .search__action-wrapper:last-child {
    display: none;
}

.addCompButton {
    margin-left: auto;
}

.compTableWrapper {
    margin-top: 0px !important;
}

.compTable{
    margin-top: 20px; 
    height: 85%;
    overflow-y: 'scroll' !important;
}


.compEmptySubText {
    line-height: 45px;
    margin-top: 5px;
    margin-bottom: 0.1em;
    color: #929292;
    font-weight: 400;
    font-size: 14px;
}