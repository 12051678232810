.notificationPopupWrapper .theatre {
   width: 410px !important;
   height: fit-content !important;
   left: 0 !important;
   right: 0 !important;
   top: 0 !important;
   bottom: 0 !important;
   margin: auto;
}

.notificationPopupWrapper .modal-body {
   overflow: auto;
   max-height: 59Vh;
   padding: 0px 11px !important;
   height: 100%;
   min-height: 30px;
}

.notificationPopupWrapper .theatre__content-wrapper {
   overflow: none !important;
   height: 100%;
}

.notifButtonPopupWrapper {
   bottom: 0px;
   margin: auto;
   width: 50%;
}

.notifButtonPopupWrap {
   height: 64px;
}

.notifButtonWrapper {
   top: 10px;
   height: 40px;
   background: #111111;
   border-radius: 999px;
   font-family: Noto IKEA Latin;
   font-size: 12px;
   font-weight: 700;
   line-height: 16px;
   letter-spacing: 0em;
   text-align: center;
   color: #FFFFFF !important;
   display: flex;
   align-items: center;
   padding: 0px 24px;
   cursor: pointer;
 }

 .notifButtonWrapper:hover {
   color: #FFFFFF !important;
 }

 .notificationPopupFlex {
   display: flex;
   border: none;
   padding: 35px 24px 24px 24px;
   flex-direction: row;
   justify-content: space-between;
}