.competitorAdminContainer {
    height: calc(100vh - 60px);
    width: 100vw;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
}

.competitorAdminContentWrapper {
    width: 100%;
    height: 100%;
}

.caInactiveText {
    color:#CA5008;
}